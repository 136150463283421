import { breakpoints, debounce } from "./utilities.js";
import "./jquery.bxslider.js";

export default function() {

	$('.m-blog-snippets:not(.js-blog-snippets-done)').each(function() {
		var wrapper = $(this);
		var small = 270;
		var medium = 270;
		var large = 310;
		var current = small;

		function _opts() {
			current = small;
			if (window.matchMedia('(min-width: ' + breakpoints.large + 'px)').matches) {
				current = large;
			}
			else if (window.matchMedia('(min-width: ' + (breakpoints.desktop) + 'px)').matches) {
				current = medium;
			}
			return {
				pager: false,
				slideWidth: current,
				minSlides: 1,
				maxSlides: 4,
				moveSlides: 1,
				slideMargin: 35,
				infiniteLoop: false,
				hideControlOnEnd: true
			}
		}

		// init
		var slider = wrapper.find('.js-gallery').bxSlider(_opts());

		window.site.win.on('resize', debounce(function() {
			if (window.matchMedia('(min-width: ' + breakpoints.large + 'px)').matches && current !== large) {
				slider.reloadSlider(_opts());
			}
			else if (window.matchMedia('(min-width: ' + breakpoints.desktop + 'px)').matches && current !== medium) {
				slider.reloadSlider(_opts());
			}
			else if (window.matchMedia('(max-width: ' + (breakpoints.desktop - 1) + 'px)').matches && current !== small) {
				slider.reloadSlider(_opts());
			}
		}))

		// done
		wrapper.addClass('js-blog-snippets-done');
	});
}
